<script setup lang="ts">
const imgList = ref<string[]>([])

async function getImgList() {
  return new Promise<void>((resolve) => {
    setTimeout(async () => {
      const list = [
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654126757826623.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654119136776254.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567792404287578.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567833143562332.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654126757826623.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654119136776254.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567792404287578.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567833143562332.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654126757826623.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654119136776254.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567792404287578.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567833143562332.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654126757826623.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/911654119136776254.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567792404287578.png',
        'https://jav-manager-image.oss-cn-hongkong.aliyuncs.com/test/910567833143562332.png'
      ]
      imgList.value = list
      resolve()
    }, 1000)
  })
}

await getImgList()
</script>

<template>
  <div><ImgWrapper v-for="(v, i) in imgList" :key="i" :src="v" mb-5 h-30 w-50 /></div>
</template>

<style lang="less" scoped></style>
